import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ExpandableChat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCircleArrowUp, faUser, faTrash, faStar, faThumbsUp, faThumbsDown, faFileWord } from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

function ExpandableChat({ title, icon, expanded, sessionId, language, resetSessionId }) {
    const [vips, setVips] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const [scrollBottom, setScrollBottom] = useState(0);
    const [events, setEvents] = useState([]);
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('chat');
    const [messages, setMessages] = useState([{
        text: t("chat_welcome_message"),
        sender: 'bot',
        isHtml: true
    }]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [calendarLoading, setCalendarLoading] = useState(false);
    const chatContentRef = useRef(null);
    const today = new Date().toISOString().split('T')[0];
    const [selectedDate, setSelectedDate] = useState(today);

    const fetchEvents = useCallback(async () => {
        setEvents([]);
        setCalendarLoading(true);
        try {
            const response = await fetch(`/api/fetch_calendar_events?date=${selectedDate}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setEvents(data.events);
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setCalendarLoading(false);
        }
    }, [selectedDate]);

    const formatDateTime = (dateTimeStr) => {
        const dateTime = new Date(dateTimeStr);
        const date = dateTime.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const time = dateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', hour12: false });
        return { date, time };
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleEventClick = (event) => {
        const { summary, description, start, end } = event;
        const { date, time: startTime } = formatDateTime(start.dateTime);
        const { time: endTime } = formatDateTime(end.dateTime);
        const eventDetails = `Titel: ${summary}\nBeschreibung: ${description}\nDatum: ${date}\nStart: ${startTime}\nEnde: ${endTime}`;
        const apiMessage = `Ich habe folgenden Kalendereintrag. Erstelle mir nützliche Informationen, um für den Termin vorbereitet zu sein: \n${eventDetails}`;
        const displayMessage = t("prepare-me-for-meeting");
        setSelectedTab('chat');
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: displayMessage, sender: 'user' }
        ]);
        handleSendMessage(apiMessage, displayMessage);
    };

    const toggle = () => setIsOpen(!isOpen);

    const resetMessage = () => {
        setMessages([{
          text: t("chat_welcome_message"),
          sender: 'bot',
          isHtml: true
        }]);
        setNewMessage("");
        resetSessionId();
    };

    useEffect(() => {
        setIsOpen(expanded);
    }, [expanded]);

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages, scrollBottom]);

    useEffect(() => {
        setMessages([{
            text: t("chat_welcome_message"),
            sender: 'bot',
            isHtml: true
        }]);
    }, [t]);

    useEffect(() => {
        if (selectedTab === 'calendar' && selectedDate != null) {
            fetchEvents();
        }
    }, [selectedDate, selectedTab, fetchEvents]);

    useEffect(() => {
        const fetchVIPs = async () => {
            try {
                const response = await fetch('/api/vip');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setVips(data);
            } catch (error) {
                console.error('Failed to fetch VIPs:', error);
            }
        };

        fetchVIPs();
    }, [refreshCount]);

    const handleSendMessage = async (message, displayMessage) => {
        if (message.trim() !== '') {
            setMessages([...messages, { text: displayMessage, sender: 'user' }]);
            setNewMessage('');
            setLoading(true);
            try {
                const response = await fetch('/api/chat', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ user_input: message, session_id: sessionId, language: language.language })
                });

                const data = await response.json();

                if (response.ok) {
                    setMessages(prevMessages => [...prevMessages, { text: data.answer, sender: 'bot', isHtml: true }]);
                } else {
                    console.error('Error fetching response:', data);
                }
            } catch (error) {
                console.error('Error fetching response:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !loading) {
            handleSendMessage(newMessage, newMessage);
        }
    };

    const handleCreateVIP = async (message) => {
        const vipData = {
            user_name: 'memo2',
            prompt: message.text
        };

        try {
            const response = await fetch('/api/vip', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(vipData)
            });

            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error('Error posting VIP:', error);
        }
        setRefreshCount(current => current + 1);
    };

    const handleDeleteVip = async (vipId) => {
        try {
            const response = await fetch(`/api/vip/${vipId}`, { method: 'DELETE' });
            if (!response.ok) {
                throw new Error('Failed to delete VIP');
            }
            setVips(currentVips => currentVips.filter(vip => vip.id !== vipId));
        } catch (error) {
            console.error('Error deleting VIP:', error);
        }
        setRefreshCount(current => current + 1);
    };

    const handleEventClickVip = (message) => {
        setSelectedTab('chat');
        setNewMessage(message);
        setScrollBottom(current => current + 1);
    };

    const generateWordDocument = async (messages) => {
        try {
            const response = await fetch('/api/generate_word_document', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ messages }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate document');
            }

            // Optionally, you might want to handle the download directly
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'ChatHistory.docx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error generating document:', error);
        }
    };

    return (
        <div className="expandable-chat-container">
            <div className="expandable-header" onClick={toggle}>
                <span>
                    <FontAwesomeIcon
                        icon={icon}
                        className='expandable-icon'
                    />
                    {` ${title}`}
                </span>
                <FontAwesomeIcon
                    icon={isOpen ? faAngleUp : faAngleDown}
                    className="arrow"
                />
            </div>
            <div className={`tab-content ${isOpen ? 'open' : ''}`}>
                {isOpen && (
                    <>
                        <div className="chat-tabs-header">
                            <button
                                className={`chat-tab-button ${selectedTab === 'chat' ? 'active' : ''}`}
                                onClick={() => setSelectedTab('chat')}
                            >
                                {t('chat-tab')}
                            </button>
                            <button
                                className={`chat-tab-button ${selectedTab === 'calendar' ? 'active' : ''}`}
                                onClick={() => setSelectedTab('calendar')}
                            >
                                {t('calendar-tab')}
                            </button>
                            <button
                                className={`chat-tab-button ${selectedTab === 'vip' ? 'active' : ''}`}
                                onClick={() => setSelectedTab('vip')}
                            >
                                {t('very-important-prompts')}
                            </button>
                        </div>
                        <div className="tabs-content">
                            {selectedTab === 'chat' && (
                                <>
                                    <div className="chat-content" ref={chatContentRef}>
                                        {messages.map((message, index) => (
                                            <div
                                                key={index}
                                                className={`message-icon-container ${message.sender}`}
                                            >
                                                {message.sender === 'bot' && (
                                                    <div className="message-icon">
                                                        E
                                                    </div>
                                                )}
                                                {message.sender === 'user' && (
                                                    <div className="star-icon" onClick={() => handleCreateVIP(message)} >
                                                        <FontAwesomeIcon
                                                            icon={faStar}
                                                        />
                                                    </div>
                                                )}
                                                <div className={`chat-message ${message.sender}`}>
                                                    <div className='markdown-container'>
                                                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                                                            {message.text}
                                                        </ReactMarkdown>
                                                        {index !== 0 && message.sender === 'bot' && (
                                                            <div className='bot-response-reaction-container'>
                                                                <div className='thumps-container'>
                                                                    <div className="thumps-icon">
                                                                        <FontAwesomeIcon
                                                                            icon={faThumbsUp}
                                                                        />
                                                                    </div>
                                                                    <div className="thumps-icon">
                                                                        <FontAwesomeIcon
                                                                            icon={faThumbsDown}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="word-icon">
                                                                    <FontAwesomeIcon
                                                                        icon={faFileWord}
                                                                        onClick={() => generateWordDocument(messages.slice(1))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {message.sender === 'user' && (
                                                    <div className="user-icon">
                                                        <FontAwesomeIcon
                                                            icon={faUser}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        {loading && (
                                            <div className="chat-bot-loader">
                                                <div className="chat-loader"></div>
                                                <span className="loading-text">{t('load-response')}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='chat-input-parent'>
                                        <div className="chat-input-container">
                                            <textarea
                                                type="text"
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                onKeyPress={handleKeyPress}
                                                placeholder={t('ask_question')}
                                                className="chat-input"
                                                disabled={loading}
                                            />
                                            <button
                                                onClick={() => handleSendMessage(newMessage, newMessage)}
                                                className="send-button"
                                                disabled={loading}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleArrowUp}
                                                    className='send-icon'
                                                />
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={resetMessage}
                                                className='clear-chat-button'
                                                title={t('reset-chat')}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className='clear-chat-icon'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedTab === 'calendar' && (
                                <div className="calendar-content">
                                    <label htmlFor="dateInput" style={{ fontWeight: '600', marginBottom: '5px', display: 'block', fontSize: '15px' }}>{t('date')}</label>
                                    <input
                                        type="date"
                                        id="dateInput"
                                        className='calendar-date-input'
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                    />
                                    {calendarLoading && (
                                        <div className="chat-bot-loader">
                                            <div className="chat-loader"></div>
                                            <span className="loading-text">{t('load-calendar')}</span>
                                        </div>
                                    )}
                                    {events.length > 0 ? (
                                        <div className='event-parent-container'>
                                            {events.map((event, index) => {
                                                const { time: startTime } = formatDateTime(event.start.dateTime);
                                                const { time: endTime } = formatDateTime(event.end.dateTime);
                                                return (
                                                    <div className='event-parent' key={index} onClick={() => handleEventClick(event)}>
                                                        <p className='event-index'>{startTime} - {endTime} Uhr</p>
                                                        <p className='event-title'>{event.summary}</p>
                                                        <div className='event-details-bottom'>
                                                            <div className='event-details-bottom-content'>
                                                                <p className='event-details-title'>{t('description')}</p>
                                                                <p>{event.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        !calendarLoading && <p>{t('no-events')}</p>
                                    )}
                                </div>
                            )}
                            {selectedTab === 'vip' && (
                            <div>
                                {vips.length > 0 ? (
                                    <div className='vip-item-parent-container'>
                                        {vips.map((vip, index) => (
                                            <div className="vip-item">
                                                <div className='vip-item-content' onClick={() => handleEventClickVip(vip.prompt)}>
                                                    <div className='vip-item-header'>#{index + 1}</div>
                                                    <div>{vip.prompt}</div>
                                                </div>
                                                <button onClick={() => handleDeleteVip(vip.id)} className='delete-vip-button'>
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        className='delete-vip-icon'
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>Keine gespeicherten Prompts</p>
                                )}
                            </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ExpandableChat;
